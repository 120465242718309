<template>
    <div style="height: 100%; width: 100%;">
      <slot v-if="!hasError"></slot>
      <div v-else>
        <h1>An error occurred!</h1>
        <p>Something went wrong. Please refresh the page.</p>
      </div>
    </div>
  </template>

  <script>
  export default {
    name: "ErrorBoundary",
    data() {
      return {
        hasError: false,
      };
    },
    errorCaptured(err, vm, info) {
      console.error('Captured an error:', err, info);
      // Report the error to Sentry
      this.$sentry.captureException(err);
      this.hasError = true;
      // Return false to prevent the error from propagating further
      return false;
    },
  };
  </script>
