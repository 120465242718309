import Vue from 'vue'
import Vuetify from 'vuetify'
import Hotjar from 'vue-hotjar'
import './bootstrap'
import './axios'
import './filters'
import config from './config';
import snackNotification from './toast'
import store from './store';
import AppNav from '../components/navigation/AppNav';
import {Plugin} from '../loader'
import {
    userHasPermission,
    userHasPermissions,
    userIsOwner,
    userIsAdministrator,
    userIsManager,
    userIsStaff
} from './helpers/permissionFunctions'
import * as Sentry from '@sentry/vue';

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: process.env.MIX_SENTRY_LARAVEL_DSN,
        integrations: [Sentry.browserTracingIntegration()],
        tracesSampleRate: process.env.MIX_SENTRY_SAMPLE_RATE,
        Vue: Vue,
    });

    Vue.use(Hotjar, {
        id: process.env.MIX_HOTJAR_ID,
        isProduction: process.env.APP_ENV === 'production',
    })
}

window.EventBus = new Vue();

Vue.use(Vuetify)
const opts =  {
    theme: {
        themes: {
            light: {
                primary: '#4253C4',
                secondary: '#C8E6FF',
                accent: '#DFE0FF',
                error: '#BA1A1A',
                success: '#6BC96E',
                warning: '#F2C266',
                primary_text: '#1B1B1F'
            }
        }
    }
};


/**
 * Load components, launch Vue and set up nav bar
 */
if (typeof window !== 'undefined' && Vue) {
    Vue.use(Plugin)
}

//load some config vars to help us out
Vue.prototype.$config = config;

if (window.Worker) {
    Vue.prototype.$logoutWarningTimerWorker = new Worker(new URL('./worker/logout-warning-timer-worker.js', import.meta.url), { type: "module" })
    Vue.prototype.$logoutTimerCountdownWorker = new Worker(new URL('./worker/logout-timer-countdown-worker.js', import.meta.url), { type: "module" })
}

// Globals
Vue.prototype.$user = userData;
Vue.prototype.$appData = appData;
Vue.prototype.$notifications = notifications;
Vue.prototype.$unreadNotificationsCount = unreadNotificationsCount;
Vue.prototype.sidebarNav = true;
Vue.prototype.$predictions = getOrganizationPredictions(userData);
Vue.prototype.$preselectedLocation = getPreselectedLocation(userData);
Vue.prototype.$preselectedTimezone = getPreselectedTimezone(userData);
Vue.prototype.$isImpersonating = isImpersonating;

Vue.prototype.$userHasPermissions = userHasPermissions
Vue.prototype.$userHasPermission = userHasPermission
Vue.prototype.$userIsOwner = userIsOwner
Vue.prototype.$authIsOwner = userIsOwner(Vue.prototype.$user)
Vue.prototype.$userIsAdministrator = userIsAdministrator
Vue.prototype.$authIsAdministrator = userIsAdministrator(Vue.prototype.$user)
Vue.prototype.$userIsManager = userIsManager
Vue.prototype.$authIsManager = userIsManager(Vue.prototype.$user)
Vue.prototype.$userIsStaff = userIsStaff
Vue.prototype.$authIsStaff = userIsStaff(Vue.prototype.$user)
Vue.prototype.$snackNotify = snackNotification

store.dispatch('setExpiresAt', sessionExpiresAt)

new Vue({
    el: '#app',
    store,
    vuetify: new Vuetify(opts),
    components: { AppNav },
    data() {
        return {
            user: this.$user,
            userLocations: null,
            allLocations: null,
            staff_groups: null,
            staff_types: null,
            requirements: null,
            profile_custom_fields: null,
            username_settings: null,
            globalLocation: this.$preselectedLocation,
            globalTimezone: this.$preselectedTimezone,
            notifications: this.$notifications,
            unreadNotificationsCount: this.$unreadNotificationsCount,
            loadingLatestNotifications: false,
            organizationPredictionsEnabled: this.$predictions,
            instancePredictionsEnabled: process.env.MIX_ENABLE_ML === undefined ? false : process.env.MIX_ENABLE_ML === 'true',
            managerStaffingDetailsLocations: null,
            websiteUrl: process.env.MIX_WEBSITE_URL
        }
    },
    created() {
        const landingBrowserAlert = document.getElementById('landing-browser-alert')
        if(landingBrowserAlert) {
            landingBrowserAlert.style.display = 'none'
        }
    }
});

if (Sentry.isInitialized()) {
    // Add Sentry Tags
    Sentry.withScope((scope) => {
        scope.setTag('vue_version', Vue.version);
    });
    // Make Sentry Global
    Vue.prototype.$sentry = Sentry;
} else {
    console.warn('Sentry is not initialized properly');
}

function getOrganizationPredictions(user) {
    if (user && (typeof user.hospital_group_id !== 'undefined')) {
        return user.hospital_group.ml_predictions_enabled
    }

    return null
}

function getPreselectedLocation(user) {
    if (user && (typeof user.selected_location !== 'undefined')) {
        return user.selected_location
    }

    return null
}

function getPreselectedTimezone(user) {
    if (user && (typeof user.timezone !== 'undefined')) {
        return user.timezone
    }

    return null
}
