<template>
    <v-data-table
        :headers="allHeaders"
        :items="formattedUsers"
        :server-items-length="totalUsers"
        :options.sync="tableOptions"
        :loading="loadingUsers"
        class="elevation-1"
        id="users-table"
        @click:row="emitUserClicked"
        @update:options="onOptionsChanged"
        :title="dataTableTitle"
        no-data-text="Add Users as Staff Members to begin building schedules and assigning shifts."
    >

        <template v-slot:item.status="{ item }">
            <v-chip :class="{ 'ds-active-chip' : item.status === 'active', 'ds-inactive-chip': item.status !== 'active' }">{{ item.status | capitalize}}</v-chip>
        </template>

        <template v-slot:item.first_name="{ item }">
            <custom-avatar :user="item" chip></custom-avatar>
        </template>

        <template v-slot:item.staff_groups="{ item }">
            <truncating-chips-list
                :items="item.staff_groups"
                @onMouseEnter="onMouseEnterTruncatingList"
                @onMouseLeave="onMouseLeaveTruncatingList"
            />
        </template>

        <template v-slot:item.staff_type="{ item }">
            {{ getStaffType(item) | capitalize }}
        </template>

        <template v-slot:item.job_titles="{ item }">
            <truncating-chips-list
                :items="item.job_titles"
                @onMouseEnter="onMouseEnterTruncatingList"
                @onMouseLeave="onMouseLeaveTruncatingList"
            />
        </template>

        <template v-slot:item.locations="{ item }">
            <user-tooltip :items="item.working_locations"></user-tooltip>
        </template>

        <template v-slot:item.last_shift_date="{ item }">
            {{ item.last_shift_date }}
        </template>

    </v-data-table>
</template>

<script>
    import userMethods from "../../lib/mixins/userMethods";
    import dataTableTitle from "../../lib/mixins/dataTableTitle";
    import CustomAvatar from "../../components/avatar/CustomAvatar";
    import UserTooltip from "../../components/profile/UserTooltip";
    import TruncatingChipsList from "../../components/common/Lists/TruncatingChipsList";

    export default {
        name: "StaffTable",
        components: {
            TruncatingChipsList,
            CustomAvatar,
            UserTooltip
        },
        mixins: [userMethods, dataTableTitle],
        props: {
            users: {
                type: Array,
                default: () => []
            },
            totalUsers: {
                type: Number,
                default: 0,
            },
            loadingUsers: {
                type: Boolean,
                default: false,
            },
            searchParams: {
                type: Object,
                default: () => {},
            },
            shouldUpdateTableOptions: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                allHeaders: [
                    {text: 'Status', value: 'status', width: '7%'},
                    {text: "Name", value: "first_name", width: "15%"},
                    {text: "Staff Group", value: "staff_groups", width: "18%", sortable: false},
                    {text: "Job Titles", value: "job_titles", width: "20%", sortable: false},
                    {text: "Employment Type", value: "staff_type", width: "10%"},
                    {text: "Last Shift", value: "last_shift_date", width: "15%"}
                ],
                locationHeader: {text: "Locations", value: "locations", width: "15%"},
                isAdmin: this.$authIsOwner,
                tableOptions: { sortBy: ['status'] },
                PERMISSIONS: this.$config.permissions
            };
        },

        methods: {

            emitUserClicked(user) {
                const {
                    $user,
                    $userHasPermission,
                    PERMISSIONS
                } = this
                if($userHasPermission($user, PERMISSIONS.USERS.VIEW_OTHERS_PROFILE) ||
                    $userHasPermission($user, PERMISSIONS.USERS.VIEW_OTHERS_PERMISSIONS) ||
                    $userHasPermission($user, PERMISSIONS.USERS.VIEW_OTHERS_JOBS) ||
                    $userHasPermission($user, PERMISSIONS.AVAILABILITY.VIEW_OTHERS)) {
                    this.$emit('user-clicked', user)
                }
            },

            setStartingHeaders() {
                this.toggleLocationHeader()
            },

            toggleLocationHeader() {
                if (!this.globalLocation && !this.allHeaders.find(header => header.value === 'locations')) {
                    this.allHeaders.splice(2, 0, this.locationHeader)
                } else {
                    this.allHeaders = this.allHeaders.filter(header => header.value !== 'locations')
                }
            },

            getStaffType(user) {
                if(user && user.staff_type && user.staff_type.name) {
                    return user.staff_type.name
                }
                return 'None'
            },

            onOptionsChanged() {
                this.$emit('update:options', this.getPaginationOptions())
            },

            getPaginationOptions() {
                const { page, itemsPerPage, sortBy, sortDesc } = this.tableOptions
                return {
                    page,
                    itemsPerPage,
                    sortBy: this.getSortBy(sortBy),
                    sortDesc: this.getSortDesc(sortDesc),
                }
            },

            getSortBy(sortByArray) {
                if(sortByArray.length > 0) {
                    const sortBy = sortByArray[0]
                    if(sortBy === 'locations') {
                        return 'jobs.location.name'
                    } else if(sortBy === 'first_name') {
                        return 'last_name'
                    } else if(sortBy === 'staff_type') {
                        return 'staff_type.name'
                    } else if(sortBy === 'max_hours_per_week') {
                        return 'staff_type.max_work_hours'
                    } else if(sortBy === 'last_shift_date') {
                        return 'last_shift.start'
                    } else if(sortBy === 'job_titles') {
                        return 'jobs.job_title.title'
                    } else if(sortBy === 'staff_groups') {
                        return 'jobs.staff_group.name'
                    } else {
                        return sortBy
                    }
                }
                return null
            },

            getSortDesc(sortDescArray) {
                if(sortDescArray.length > 0) {
                    return sortDescArray[0]
                }
                return null
            },

            getFormattedStaffGroups(user, location) {
                const { jobs } = user
                if(!Array.isArray(jobs) || jobs.length === 0) {
                    return null
                }
                let filteredJobs = Array.from(jobs)
                if(location) {
                    filteredJobs = filteredJobs.filter(job => job.location.id === location._id)
                }
                return _.uniq(filteredJobs
                    .filter(job => job.staff_group)
                    .map(job => job.staff_group.name))
            },

            getFormattedJobTitles(user, location) {
                const { jobs } = user
                if(!Array.isArray(jobs) || jobs.length === 0) {
                    return null
                }

                let filteredJobs = Array.from(jobs)
                if(location) {
                    filteredJobs = filteredJobs.filter(job => job.location.id === location._id)
                }
                filteredJobs = filteredJobs.filter(job => job.job_title)
                return _.uniq(filteredJobs
                    .map(job => job.job_title.title))
            }
        },

        computed: {
            globalLocation() {
                return this.$root.globalLocation;
            },

            globalTimezone() {
                return this.$root.globalTimezone
            },

            formattedUsers() {
                return this.users.map(user => {
                    user.last_login_label = this.formatLastLogin(user, this.globalTimezone)
                    user.staff_groups = this.getFormattedStaffGroups(user, this.globalLocation)
                    user.job_titles = this.getFormattedJobTitles(user, this.globalLocation)
                    user.working_locations = this.formatLocations(user)
                    user.last_shift_date = this.formatLastShift(user, this.globalTimezone)
                    return user
                })
            },
        },

        mounted() {
            this.setStartingHeaders()
        },

        watch: {
            globalLocation() {
                if(this.shouldUpdateTableOptions) {
                    this.toggleLocationHeader()
                    const tableOptionsCopy = {...this.tableOptions}
                    this.tableOptions = {
                        ...this.tableOptions,
                        page: 1,
                        sortBy: ['status'],
                        sortDesc: []
                    }
                    if(_.isEqual(tableOptionsCopy, this.tableOptions)) {
                        this.onOptionsChanged()
                    }
                }
            }
        }
    }
</script>

<style scoped>
    #users-table :deep(tr:hover) {
        cursor: pointer;
    }
</style>
