import ErrorBoundary from './components/common/GlobalError/ErrorBoundary.vue'

const Pages = {
    Auth: [
        'Login',
        'PostIdpLogout'
    ],
    Dashboard: [
        'index'
    ],
    Users: [
        'index'
    ],
    Profile: [
        'index'
    ],
    Schedules: [
        'index'
    ],
    Shifts: [
        'index',
    ],
    Notifications: [
        'index'
    ],
    Reporting: [
        'index'
    ],
    Organization: [
        'index'
    ],
    Onboarding: [
        'index'
    ],
    StaffingAutomations: [
        'index'
    ]
}

function toKebabCase(string) {
    return string
        .replace(/([a-z])([A-Z])/g, '$1-$2')
        .replace(/\s+/g, '-')
        .toLowerCase()
}

function withErrorBoundary(component) {
    return {
        functional: true,
        render(h, ctx) {
            return h(
                ErrorBoundary,
                {
                    ...ctx.data, // Pass all attributes, events, and properties to the ErrorBoundary
                },
                [
                    h(component, {
                        ...ctx.data, // Pass all attributes, events, and properties to the wrapped component
                        scopedSlots: ctx.scopedSlots, // Ensure scoped slots are passed through
                    }),
                ]
            );
        },
    };
}

const Plugin = {
    install(Vue) {
        for (const page in Pages) {
            Pages[page].forEach(componentName => {  
                const originalComponent = require('./pages/' + page + '/' + componentName).default;
                const wrappedComponentName = toKebabCase(page) + '-' + componentName.toLowerCase();

                // Wrap the original component with ErrorBoundary
                const wrappedComponent = withErrorBoundary(originalComponent);

                Vue.component(wrappedComponentName, wrappedComponent)

            })
        }
    }
}

export { Plugin };
